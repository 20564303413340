import React, { useContext, useState } from "react";
import { Box } from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import landing from "assets/images/landing.jpg";
import AuthHeader from "./Auth/AuthHeader";
import "./authLayout.scss";

const AuthLayout = ({ children }) => {
  const imageSrc = landing;
  console.log("landing", imageSrc);
  return (
    <Box className="full_wrapper">
      <AuthHeader />
      <Box className="auth_container" direction="row">
        <Box className="left_panel" direction="column">
          {children}
        </Box>
        <Box className="right_panel"></Box>
      </Box>
      <Box className="bg_container_2">
        <img className="img-tag" src={landing} alt="landing" />
      </Box>
    </Box>
  );
};

export default AuthLayout;
